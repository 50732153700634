import _ from "lodash";
import {format} from "@/plugins/dateFns";
import {isLocal} from "frontend.common/src/EnvironmentConstants";
import {AnyObject} from "@/types/main";
import {LatitudeAndLongitude} from "@/models/PostalAddress";

declare var window: any;

const DEFAULT_DEBOUNCE_TIME = 250;

const UtilityFunctions = {

    isDev: () => (isLocal),

    isTestingMode: () => window._env_.REACT_APP_TESTING_MODE === "true",

    debounce: (fn) => {
        return _.debounce(fn, DEFAULT_DEBOUNCE_TIME);
    },

    /**
     *
     * @param  {Object}        subscriberContent
     * @param  {boolean}       getFromLr2
     * @param  {boolean}       checkLatAndLng    - si <code>TRUE</code> alors une erreur sera levé, sinon latitude et longitude seront défini à 0
     *
     * @return {PostalAddress}
     */
    getSubscriberAddressFromContent(subscriberContent: AnyObject, getFromLr2: boolean, checkLatAndLng: boolean = true) {
        const baseObject = getFromLr2 ? subscriberContent.lr2?.address || {} : subscriberContent;

        const address = baseObject.address;
        const zipCode = baseObject.zipCode;
        const city = baseObject.city;
        const cityInsee = baseObject.cityInsee;
        const cityLine5 = baseObject.cityLine5;
        const additionalAddress = baseObject.additionalAddress;

        const fullAddress = this.getFullAddressFromAddress({address, zipCode, city, cityLine5});

        const latLng: { latitude: any; longitude: any } = this.parseLatLng({
            latitude: baseObject.addressLatitude,
            longitude: baseObject.addressLongitude,
        }, checkLatAndLng);

        const result: any = {
            address,
            zipCode,
            city,
            cityInsee,
            additionalAddress,
            cityLine5,
            fullAddress,
            ...latLng,
        };

        if (baseObject.addressLatitudeGouv) {
            const latLngGouv: { latitude: any; longitude: any } = this.parseLatLng({
                latitude: baseObject.addressLatitudeGouv,
                longitude: baseObject.addressLongitudeGouv,
            }, checkLatAndLng);

            result.latitudeGouv = latLngGouv.latitude;
            result.longitudeGouv = latLngGouv.longitude;
        }

        return result;
    },

    /**
     *
     * @param  {Object} o
     * @param  {String} o.address
     * @param  {String} o.zipCode
     * @param  {String} o.zipCode
     * @param  {String} o.cityLine5
     *
     * @return {string}             - combinaison de "{address} {zipCode} {city} {cityLine5}"
     */
    getFullAddressFromAddress({address = "", zipCode = "", city = "", cityLine5 = ""}) {
        return [address, zipCode, city, cityLine5].map(s => s?.trim()).filter(v => !!v).join(" ");
    },

    /**
     *
     * @param   {string|number}                         latitude
     * @param   {string|number}                         longitude
     * @param   {boolean}                               [throwError=true]
     * @returns {{latitude: number, longitude: number}}
     */
    parseLatLng({
        latitude,
        longitude,
    }: { latitude: string | number, longitude: string | number }, throwError: boolean = true): LatitudeAndLongitude {
        latitude = Number(latitude);
        longitude = Number(longitude);

        if (isNaN(latitude) || isNaN(longitude)) {
            if (throwError) {
                throw new Error("Latitude or longitude is invalid");
            } else {
                latitude = 0;
                longitude = 0;
            }
        }

        return {
            latitude,
            longitude,
        };
    },

    /**
     * Format the search query:
     * - replace accented characters
     * - lowercase
     * - replace "SAINT[E]" by "ST[E]" (only if "SAINT" is a whole word)
     * - replace "-" by " "
     * - replace "'" by " "
     * - trim
     * - replace multiple space by one space
     *
     * @param   {string} address
     * @returns {string}
     */
    sanitizeAddress: function (address: string | null | undefined) {
        if (!address) {
            return "";
        }
        return this.unaccent(address)
            .toUpperCase()
            .replaceAll("'", " ")
            .replaceAll("-", " ")
            .replaceAll(/\bSAINT\b/g, "ST")
            .replaceAll(/\bSAINTE\b/g, "STE")
            .trim()
            .replaceAll(/\s+/g, " ");
    },

    /**
     * Retire les accents d'une chaine de caractères.
     * @param   {string} str
     * @returns {string}
     */
    unaccent: function (str) {
        if (!str) {
            return str;
        }

        // see: https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
        str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        return str;
    },

    /**
     *
     * @param {*}        file
     * @param {Function} cb
     */
    getBase64: (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    },

    /**
     * Génère un join type "Espèces, chèque ou virement"
     * @param   {Array}  array         (ex : ["Espèces", "chèque", "virement"])
     * @param   {string} separator     (ex : ", ")
     * @param   {string} lastSeparator (ex : " ou ")
     * @returns {string}               (ex : "Espèces, chèque ou virement")
     */
    arrayJoinWithLastSeparator(array, separator, lastSeparator) {
        if (!array?.length) {
            return "";
        }

        return array.reduce((text, value, i, array) => text + (i < array.length - 1 ? separator : lastSeparator) + value);
    },


    secondsUnixToTime(unixTime) {
        let date = new Date(parseInt(unixTime));
        return format(date, "HH'h'mm");
    },


    secondsToHms(seconds) {
        seconds = Number(seconds);
        let h = Math.floor(seconds / 3600);
        let m = Math.floor(seconds % 3600 / 60);
        let hDisplay = h > 0 ? h + "h " : "";
        let mDisplay = m > 0 ? m + "min " : "";
        return hDisplay + mDisplay;
    },

    ensureArray<T>(value?: T | T[]): T[] {
        if (Array.isArray(value)) {
            return value;
        } else if (value === undefined || value === null) {
            return [];
        } else {
            return [value];
        }
    },

    isImageExist(src: string): Promise<boolean> {
        return new Promise(resolve => {
            const img = new Image();
            img.onload = () => resolve(true);
            img.onerror = () => resolve(false);

            img.src = src;
        });
    },
};

export default UtilityFunctions;