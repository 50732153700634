import _ from "lodash";
import UtilityFunctions from "@/utils/UtilityFunctions";

/**
 * @typedef State
 * @property {string}                   currentSchemaName
 * @property {boolean}                  helperValidated
 * @property {Keycloak.KeycloakProfile} userProfile
 */

/**
 * @readonly
 * @enum {string}
 */
// eslint-disable-next-line no-unused-vars
const OkinaEnv = {
    RNA: "RNA",
};

const propInLocalStorage = [
    "helperValidated",
];

class Store {

    constructor() {
        /**
         *
         * @type {State}
         * @private
         */
        this._state = {
            userProfile: undefined,
            commonCode: window._env_.REACT_APP_DEFAULT_COMMON_CODE,
            helperValidated: false,
        };

        /**
         *
         * @type {Keycloak.KeycloakInstance}
         * @private
         */
        this._keycloak = undefined;

        this.loadState();
    }

    /**
     * @private
     */
    loadState() {
        let localStorageState = localStorage.getItem(process.env.REACT_APP_STORAGE_KEY);
        if (localStorageState) {
            try {
                localStorageState = JSON.parse(localStorageState);
                this._state = _.merge(this._state, localStorageState);
            } catch (e) {
                console.warn("The state can't be parsed", localStorageState);
            }
        }

        if (this.isEnvDev) {
            console.log("Store: State", this._state);
        }
    }

    /**
     * @private
     */
    saveState() {
        const obj = {};
        for (const stateKey of propInLocalStorage) {
            obj[stateKey] = this._state[stateKey];
        }

        localStorage.setItem(window._env_.REACT_APP_STORAGE_KEY, JSON.stringify(obj));
    }

    /**
     *
     * @param {Keycloak.KeycloakInstance} keycloak
     */
    async loadKeycloak(keycloak) {
        this._keycloak = keycloak;
        if (keycloak.authenticated) {
            this._state.userProfile = await keycloak.loadUserProfile();
            if (this.isEnvDev) {
                console.log("Store: currentCommonCode", this.commonCode);
                console.log("Store: userProfile", this._state.userProfile);
                console.log("Store: Token parsed", this._keycloak.tokenParsed);
            }
        }
    }

    get isEnvDev() {
        return UtilityFunctions.isDev();
    }

    /**
     *
     * @returns {boolean}
     */
    get isAuthenticated() {
        return this._keycloak?.authenticated || false;
    }

    /**
     * The authentication keycloak token
     * @returns {string}
     */
    get authToken() {
        return this._keycloak?.token;
    }

    /**
     * Retourne le code commun du schema
     * @returns {string}
     */
    get commonCode() {
        return window._env_.REACT_APP_DEFAULT_COMMON_CODE || this._state.commonCode;
    }

    set commonCode(code) {
        this._state.commonCode = code;
    }

    /**
     * Return le keycloak UUID de l'utilisateur connecté
     * @returns {string}
     */
    get userUUID() {
        return this._keycloak?.tokenParsed.sub;
    }

    get userProfile() {
        return this._state.userProfile;
    }

    /**
     * Return l'utilisateur a visité la page d'aide
     * @returns {*}
     */
    get helperValidated() {
        return this._state.helperValidated;
    }

    set helperValidated(value) {
        this._state.helperValidated = value;
        this.saveState();
    }

    get baseUrl() {
        const host = window.location.origin;
        let baseUrl = `${host}/#`;

        if (!window._env_.REACT_APP_DEFAULT_COMMON_CODE) {
            baseUrl += "/" + this.commonCode;
        }

        return baseUrl;
    }

}

const store = new Store();
export default store;
