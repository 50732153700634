import React, {useMemo} from "react";
import {Box, Grid, List, ListSubheader} from "@mui/material";
import Icons from "@/components/common/styles/Icons";
import ListSubscribersRegisteredItem from "@/components/subscriber-list/ListSubscribersRegisteredItem";
import Icon from "@/components/common/ui/Icon";
import {SubscriberRequest} from "@/service/SubscriberService";
import ListSubscribersUnregisteredItem from "@/components/subscriber-list/ListSubscribersUnregisteredItem";
import {LegalRepresentative} from "@/components/common/LegalRepresentativeContext";

export type ListSubscribersProps = {
    legalRepresentative: LegalRepresentative,
    subscriberRequests: SubscriberRequest[],
}

const ListSubscribers = ({
    legalRepresentative,
    subscriberRequests = [],
}: ListSubscribersProps) => {

    const subscriberRequestSplited = useMemo(() => {
        const notStartedToRenew: SubscriberRequest[] = [];
        const notStartedToRegister: SubscriberRequest[] = [];
        const startedOrFinished: SubscriberRequest[] = [];
        const notMasterLegalRepresentative: SubscriberRequest[] = [];

        for (const subscriberRequest of subscriberRequests) {
            if (!subscriberRequest?.master) {
                notMasterLegalRepresentative.push(subscriberRequest);
            } else {
                if (subscriberRequest.lastStepCode == null) {
                    if (subscriberRequest.subscriberId == null) {
                        notStartedToRegister.push(subscriberRequest);
                    } else {
                        notStartedToRenew.push(subscriberRequest);
                    }
                } else {
                    startedOrFinished.push(subscriberRequest);
                }
            }
        }

        return {
            notStartedToRenew,
            notStartedToRegister,
            startedOrFinished,
            notMasterLegalRepresentative,
        };
    }, [subscriberRequests]);

    const notStartedToRenew = subscriberRequestSplited.notStartedToRenew;
    const notStartedToRegister = subscriberRequestSplited.notStartedToRegister;
    const startedOrFinished = subscriberRequestSplited.startedOrFinished;
    const notMasterLegalRepresentative = subscriberRequestSplited.notMasterLegalRepresentative;

    return (
        <Box className="mobile-top-shadow">
            <Grid container spacing={2}>
                {(notStartedToRenew.length > 0 || notStartedToRegister.length > 0) &&
                    <Grid item xs={12} md={6}>
                        <Box p={2} className="desktop-rounded-box">
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <h3>
                                        <Icon src={Icons.addChild}/> Enfants à inscrire
                                    </h3>
                                </Grid>
                                <Grid item xs={12}>
                                    <List>
                                        {!!notStartedToRenew.length &&
                                            <>
                                                <ListSubheader>À renouveler</ListSubheader>
                                                {notStartedToRenew.map(s =>
                                                    <ListSubscribersUnregisteredItem key={s.uuid} subscriberRequest={s}/>
                                                )}
                                            </>
                                        }
                                        {!!notStartedToRegister.length &&
                                            <>
                                                <ListSubheader>À inscrire</ListSubheader>
                                                {notStartedToRegister.map(s =>
                                                    <ListSubscribersUnregisteredItem key={s.uuid} subscriberRequest={s}/>
                                                )}
                                            </>
                                        }
                                    </List>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                }

                {(startedOrFinished.length > 0 || notMasterLegalRepresentative.length > 0) &&
                    <Grid item xs={12} md={6}>
                        <Box p={2} className="desktop-rounded-box">
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <h3>
                                        <Icon src={Icons.childAvatar}/> Mes inscriptions
                                    </h3>
                                </Grid>
                                <Grid item xs={12}>
                                    <List>
                                        {startedOrFinished.map((s) =>
                                            <ListSubscribersRegisteredItem key={s.uuid} subscriberRequest={s} legalRepresentative={legalRepresentative}/>
                                        )}

                                        {notMasterLegalRepresentative.map((s) =>
                                            <ListSubscribersRegisteredItem key={s.uuid} subscriberRequest={s} legalRepresentative={legalRepresentative}/>
                                        )}
                                    </List>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                }
            </Grid>
        </Box>
    );
};

export default ListSubscribers;
