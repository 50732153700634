import AbstractService from "./AbstractService";
import {AnyObject} from "@/types/main";

// eslint-disable-next-line no-unused-vars

class LegalRepresentativeService extends AbstractService {

    constructor() {
        super("/public/fiti/subscription/dynamic/legal-representative");
    }

    getInfo() {
        return this.get();
    }

    hasBirthDate(lrFormId, schemaName) {
        return this.get<boolean>(["has-birthdate", lrFormId], {
            headers: {
                "Content-Type": "text/plain",
                "x-okina-referential": schemaName,
            },
            public: true,
        });
    }

    controlAge(lrFormId, birthDate, schemaName) {
        return this.post<{ email: string }>(["age-control", lrFormId], birthDate, {
            headers: {
                "Content-Type": "text/plain",
                "x-okina-referential": schemaName,
            },
            public: true,
        });
    }

    tryToLinkLoggedInLegalRepresentativeToGivenLrFormId(lrFormId, loggedInUserKeycloakUUID, schemaName) {
        return this.put<any>(["try-associate-lr-logged-in-w-given-lr", lrFormId], {}, {
            params: {
                loggedInUserKeycloakUUID,
            },
            headers: {
                "Content-Type": "text/plain",
                "x-okina-referential": schemaName,
            },
            public: true,
        })
    }

    associateKeycloakAccount(lrFormId) {
        return this.put(["associate-keycloak-account", lrFormId]);
    }

    update(legalRepresentative) {
        return this.put("", legalRepresentative);
    }

    edit(legalRepresentative) {
        return this.put("edit", legalRepresentative);
    }

    analyzeFinishRegister(legalRepresentative) {
        return this.post("analyze-finish-register", legalRepresentative);
    }

    finishRegister(legalRepresentative) {
        return this.post("finish-register", legalRepresentative);
    }

    sendValidationEmail() {
        return this.get("send-validation-email");
    }

    validateEmailToken(token) {
        return this.get(["validate-email-token", token], {
            public: true,
        });
    }

    validateSMSCode(code: string, token: any) {
        return this.post(`validate-sms-code/${code}`, token);
    }

    checkSMSValidationAvailability() {
        return this.get(`is-sms-validation-available`);
    }

    sendSMSCode() {
        return this.get(`send-sms-code`);
    }

    getRemainingRequiredDocuments() {
        return this.get("file/remaining-required-documents");
    }

    /**
     *
     * @param   {File}                      file
     * @param   {string}                    documentTypeCode
     * @returns {Promise<AxiosResponse<*>>}
     */
    addFile(file, documentTypeCode) {
        const fd = new FormData();
        fd.append("documentTypeCode", documentTypeCode);
        fd.append("file", file);

        return this.post("file", fd);
    }

    /**
     * Retourne la liste des abonnements à payer
     */
    getSubscriptionsToPay() {
        return this.get("subscriptions-to-pay");
    }

    getRenewableSubscribers() {
        return this.get<AnyObject[]>("subscribers/renewable");
    }

    getLr2Wish(token) {
        return this.get(["lr2-wish", token], {public: true});
    }

    setLr2WishStatus(token, status, acceptTravelerInformationCampaign) {
        return this.post(["lr2-wish", token], {status}, {
            params: {
                acceptTravelerInformationCampaign: acceptTravelerInformationCampaign,
            },
            public: true,
        });
    }

    getMailingWish() {
        return this.get("mailing-wish");
    }
}

export default new LegalRepresentativeService();